import React from 'react'
import OddsRow from './OddsRow'

function OddsTable(props) {
    let betType = `${props.betType}${props.betLength === 'half' ? '_1h' : ''}`
    let [odds, setOdds] = React.useState({'totals': [], 'spreads': [], 'h2h': [],
        'spreads_1h': [], 'totals_1h': [], 'h2h_1h': []})
    let [fiveMinAgo, setFiveMinAgo] = React.useState({'totals': [], 'spreads': [], 'h2h': [],
        'spreads_1h': [], 'totals_1h': [], 'h2h_1h': []})
    let [hourAgo, setHourAgo] = React.useState({'totals': [], 'spreads': [], 'h2h': [],
        'spreads_1h': [], 'totals_1h': [], 'h2h_1h': []})

    var bookOrder = ['betmgm',
        'draftkings', 'fanduel',
        'williamhill_us', 'bovada', 'betrivers']


    if (props.betLength === 'half') {
        bookOrder = ['caesars', 'gold nug', 'barstool2', 'fanduel2', 'wynn']
        // bookOrder.push('wynn')
        // bookOrder.push('fanduel2')
    }

    var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        var myInit = {
            method: 'GET',
            headers: myHeaders,
        };
    function getMarkets() {
        let apiEndpoint = `https://onemoreload.s3.amazonaws.com/all_odds_cbb_latest.json`
        fetch(apiEndpoint, myInit)
        .then(response => response.json())
        .then(response => setOdds(response));
    }

    function getPast() {
        let fiveMin = 'https://onemoreload.s3.amazonaws.com/archives/five_min_ago.json'
        fetch(fiveMin, myInit)
        .then(response => response.json())
        .then(response => setFiveMinAgo(response));
        let hourAgo = 'https://onemoreload.s3.amazonaws.com/archives/hour_ago.json'
        fetch(hourAgo, myInit)
        .then(response => response.json())
        .then(response => setHourAgo(response));
    }

    React.useEffect(() => {
        setInterval(getMarkets, 30000)
        setInterval(getPast, 120000)
        getPast()
    }, [])

    React.useEffect(() => {
        getMarkets()
    }, [betType])

    const now = new Date()
    let games = odds[betType]
    if (props.timePeriod === 'five_min') {
        games = fiveMinAgo[betType]
    } else if (props.timePeriod === 'hour') {
        games = hourAgo[betType]
    }

    games.forEach(x => x.startDateTime = new Date(x.commence_time))
    games.forEach(x => x.isStarted = x.startDateTime < now)
    let rows = games.filter(x => !x.isStarted || props.showStartedGames).map(x => <OddsRow game={x} betType={betType} key={x.id}
        bookOrder={bookOrder} betLength={props.betLength} referenceType={props.referenceType}/>)
    let headerRow = ['Start Time', 'Game'].concat(bookOrder).map(x => {
        let cellName = 'vegas-edge'
        cellName = x.startsWith('fanduel') ? 'hard-edge' : cellName
        return <td className={cellName} key={x}>{x}</td>
    })
    return <div>
        {/* <table>
        <thead><tr>{headerRow}</tr></thead><tbody style={{display:"none"}}>{rows}</tbody></table> */}
        <table className="odds">
        <thead><tr>{headerRow}</tr></thead><tbody>{rows}</tbody></table>
        </div>
}

export default OddsTable;
