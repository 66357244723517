import React from 'react'

function BetTypeToggle(props) {

    return (
        <div className="toggle">
            <button onClick={() => props.setBetType('spreads')}
                className={props.betType === 'spreads' ? 'selected' : ''}>Spreads</button>
            <button onClick={() => props.setBetType('totals')}
                className={props.betType === 'totals' ? 'selected' : ''}>Totals</button>
            <button onClick={() => props.setBetType('h2h')}
                className={props.betType === 'h2h' ? 'selected' : ''}>Moneylines</button>
        </div>
    )
}

export default BetTypeToggle