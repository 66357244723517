import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Calculator from './Calculator';
import Players from './Components/Players';
import { BrowserRouter, Routes, Route } from "react-router-dom";

ReactDOM.render(
  // <React.StrictMode><App /></React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/calc" element={<Calculator />} />
      <Route path="/players" element={<Players />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
