import React, { useEffect } from 'react';

function StartedGameToggle(props) {
    let [checked, setChecked] = React.useState(false)
    const handleCheck = () => {
        setChecked(!checked)
        
    }

    React.useEffect(() => {
        props.setShowStartedGames(checked)
    }, [checked])
    
    return (
        <div>
            <input type="checkbox" id="started" name="started" checked={checked}
                onChange={handleCheck}/>
            <label>Show Started Games</label>
        </div>
    )
}

export default StartedGameToggle