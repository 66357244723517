import React from 'react'
import PlayersRow from './PlayersRow'

function Players(props) {

    let [players, setPlayers] = React.useState([])
    const bookOrder = ['draftkings', 'caesars', 'fanduel', 'wynn', 'betmgm',
        'gold nug', 'barstool', 'foxbet', 'bovada'];

    var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        var myInit = {
            method: 'GET',
            headers: myHeaders,
        };
    function getMarkets() {
        let apiEndpoint = `https://onemoreload.s3.amazonaws.com/player_props_all.json`
        fetch(apiEndpoint, myInit)
        .then(response => response.json())
        .then(response => setPlayers(response));
    }

    React.useEffect(() => {
        getMarkets()
        setInterval(getMarkets, 30000)
    }, [])

    let rows = players.map(x => <PlayersRow game={x} key={x.id} bookOrder={bookOrder}/>)
    let headerRow = ['Player'].concat(bookOrder).map(x => {
        return <td key={x}>{x}</td>
    })
    return <div>
        <table>
        <thead><tr>{headerRow}</tr></thead><tbody>{rows}</tbody></table>
        </div>
}

export default Players;
