export function americanToPercent(americanOdds) {
    if (americanOdds < 0) {
        return americanOdds/(americanOdds-100)
    } else {
        return 100/(americanOdds + 100)
    }
}

export function americanToDecimal(americanOdds) {
    return 1/americanToPercent(americanOdds)
}