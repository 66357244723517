import React from 'react'

function FullGameToggle(props) {

    return (
        <div className="toggle">
            <button onClick={() => props.setBetLength('full')}
                className={props.betLength === 'full' ? 'selected' : ''}>Full Game</button>
            <button onClick={() => props.setBetLength('half')}
                className={props.betLength === 'half' ? 'selected' : ''}>Half</button>
        </div>
    )
}

export default FullGameToggle