import React from 'react'

function Calculator(props) {
    let [lineNum, setLineNum] = React.useState()
    let [price, setPrice] = React.useState()
    let [otherSidePrice, setOtherSidePrice] = React.useState()

    const handleLineChange = function(event) {
        setLineNum(event.target.value)
    }
    const handlePriceChange = function(event) {
        setPrice(event.target.value)
    }
    const handleOtherSidePriceChange = function(event) {
        setOtherSidePrice(event.target.value)
    }    
    
    return (
        <div>
            <h1>{lineNum}</h1>
            <table>
                <tr>
                    <td>Line</td>
                    <td>
                    <input type="text" value={lineNum} onChange={handleLineChange}></input>
                    </td>
                </tr>
                <tr>
                    <td>Price</td>
                    <td>
                    <input type="text" value={lineNum} onChange={handlePriceChange}></input>
                    </td>
                </tr>
                <tr>
                    <td>Opp. Price</td>
                    <td>
                    <input type="text" value={lineNum} onChange={handleOtherSidePriceChange}></input>
                    </td>
                </tr>
            </table>

        </div>
    )
}

export default Calculator