function TimeMachine(props) {
    return (
        <div className={''}>
            <span>Time Period: </span>
            <input type="radio" onChange={() => props.setTimePeriod('now')}
                checked={props.timePeriod === 'now'}></input>
            <label>Now </label>
            <input type="radio" onChange={() => props.setTimePeriod('five_min')}
                checked={props.timePeriod === 'five_min'}></input>
            <label>10 Min. Ago </label>
            <input type="radio" onChange={() => props.setTimePeriod('hour')}
                checked={props.timePeriod === 'hour'}></input>
            <label>Hour Ago </label>
          
        </div>
    )
}

export default TimeMachine