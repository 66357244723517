function cdf(x, mean, variance) {
    return 0.5 * (1 + erf((x - mean) / (Math.sqrt(2 * variance))));
}
  
export function erf(x) {
    // save the sign of x
    var sign = (x >= 0) ? 1 : -1;
    x = Math.abs(x);

    // constants
    var a1 =  0.254829592;
    var a2 = -0.284496736;
    var a3 =  1.421413741;
    var a4 = -1.453152027;
    var a5 =  1.061405429;
    var p  =  0.3275911;

    // A&S formula 7.1.26
    var t = 1.0/(1.0 + p*x);
    var y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
    return sign * y; // erf(-x) = -erf(x);
}

export function erfinv(x){
    var z;
    var a  = 0.147;                                                   
    var the_sign_of_x;
    if(0==x) {
        the_sign_of_x = 0;
    } else if(x>0){
        the_sign_of_x = 1;
    } else {
        the_sign_of_x = -1;
    }

    if(0 != x) {
        var ln_1minus_x_sqrd = Math.log(1-x*x);
        var ln_1minusxx_by_a = ln_1minus_x_sqrd / a;
        var ln_1minusxx_by_2 = ln_1minus_x_sqrd / 2;
        var ln_etc_by2_plus2 = ln_1minusxx_by_2 + (2/(Math.PI * a));
        var first_sqrt = Math.sqrt((ln_etc_by2_plus2*ln_etc_by2_plus2)-ln_1minusxx_by_a);
        var second_sqrt = Math.sqrt(first_sqrt - ln_etc_by2_plus2);
        z = second_sqrt * the_sign_of_x;
    } else { // x is zero
        z = 0;
    }
    return z;
}

export function calcMean(point, perc, stddev) {
    return point-erfinv(2*perc-1)*stddev*(2**.5)
}

// function 

// export calcMean

// 2p-1 = erf((10-u)/9.5*2**.5)
// -0.0888565 = (10-u)/(9.5**sqrt2)
// console.log(10-erfinv(-.1)*9.5*(2**.5))
// console.log(calcMean(10, .45))
// export erfinv;
/*
const e = JSON.parse(fs.readFileSync('erf_inv.json'))
console.log(e)
function erf_inv(x) {
    return e[Math.round(x*1000)/1000]
}
console.log(erf_inv(.653))
*/
/*
let m = {}
for (var i=-40000; i < 40000; i++) {
    x = i/10000
    y = erf(x)
    m[Math.round(y*1000)/1000] = x
}
console.log(m)
*/