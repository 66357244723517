import React from 'react'
import OddsTable from './Components/OddsTable'
import BetTypeToggle from './Components/BetTypeToggle'
import StartedGameToggle from './Components/StartedGameToggle'
import FullGameToggle from './Components/FullGameToggle'
import HighlightSelect from './Components/HighlightSelect'
import TimeMachine from './Components/TimeMachine'

function App() {
  let [betType, setBetType] = React.useState('spreads')
  let [betLength, setBetLength] = React.useState('full')
  let [showStartedGames, setShowStartedGames] = React.useState(false)
  let [referenceType, setReferenceType] = React.useState('median')
  let [timePeriod, setTimePeriod] = React.useState('now')

  return (
    <div className="App">
      <BetTypeToggle setBetType={setBetType} betType={betType}/>
      <FullGameToggle setBetLength={setBetLength} betLength={betLength}/>
      <div id='settings' className='toggle'>
        <TimeMachine setTimePeriod={setTimePeriod} timePeriod={timePeriod} />
        <StartedGameToggle setShowStartedGames={setShowStartedGames}/>

      </div>
      <OddsTable betType={betType} betLength={betLength} showStartedGames={showStartedGames}
        referenceType={referenceType} timePeriod={timePeriod}/>
    </div>
  );
}

export default App;
