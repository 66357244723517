import { americanToPercent, americanToDecimal } from '../utils/oddsConvert'
import { calcMean, erf, erfinv} from '../utils/erf'

function sign(x) {
    if (x===0) {
        return 0
    } else {
        return Math.abs(x)/x
    }
}

const reduceWinProb = (x) => .5 + (x-.5)/2

function median(n) {
    n.sort((a,b) => a-b)
    const nums = n.filter(x => x !== undefined)
    if (Math.floor(nums.length/2) === nums.length/2) {
        return (nums[nums.length/2] + nums[nums.length/2-1])/2
    } else {
        return nums[Math.floor(nums.length/2)]
    }
}

function OddsRow(props) {
    const bookOrder = props.bookOrder
    const topName = props.betType.startsWith('totals') ? 'Over' : props.game.away_team
    const bottomName = props.betType.startsWith('totals') ? 'Under' : props.game.home_team

    function selectMarket(markets, book) {
        let market = {
                key: book,
                sides: [{name: topName, price:"", point: ""},{name: bottomName, price:"", point: ""}]
            }
        let marketList = markets.filter(x => x.key === book)
        if (marketList.length > 0) {
            market = Object.assign(market, marketList[0])
        }
        return market
    }

    let pinnyMarket = selectMarket(props.game.markets, 'pinnacle')
    if (pinnyMarket.sides[0].price === '') {
        pinnyMarket = selectMarket(props.game.markets, 'pinnacle_2')
    }

    let pinnyTopSide, pinnyBottomSide
    if (pinnyMarket.sides[0].name === topName) {
        pinnyTopSide = pinnyMarket.sides[0]
        pinnyBottomSide = pinnyMarket.sides[1]
    } else {
        pinnyTopSide = pinnyMarket.sides[1]
        pinnyBottomSide = pinnyMarket.sides[0]
    }

    let stdDev = props.betType == 'spreads' ? 9.5 : 15.3
    stdDev = props.betLength == 'half' ? 9 : stdDev
    const vig = americanToPercent(pinnyTopSide.price) + americanToPercent(pinnyBottomSide.price)
    const pinnyTrueOddsTop = americanToPercent(pinnyTopSide.price)/vig
    let trueMeanPinny = calcMean(pinnyTopSide.point, pinnyTrueOddsTop, stdDev)
    if (props.betType.startsWith('totals')) {
        trueMeanPinny = calcMean(pinnyTopSide.point, 1-pinnyTrueOddsTop, stdDev)
    }

    // get the medians:
    let trueMeans = bookOrder.map(x => {
        let cellMarket = selectMarket(props.game.markets, x)
        let topSide, bottomSide
        if (cellMarket.sides[0].name === topName) {
            topSide = cellMarket.sides[0]
            bottomSide = cellMarket.sides[1]
        } else {
            topSide = cellMarket.sides[1]
            bottomSide = cellMarket.sides[0]
        }
        if (topSide.point === '') {
            return undefined
        }
        const vig = americanToPercent(topSide.price) + americanToPercent(bottomSide.price)
        let trueOddsTop = americanToPercent(topSide.price)/vig
        if (props.betType.startsWith('totals')) {
            trueOddsTop = 1-trueOddsTop
        }
        const tm = calcMean(topSide.point, trueOddsTop, stdDev)
        return tm
    })
    const trueMeanMedian = median(trueMeans)
    // done with medians

    let rowCells = bookOrder.map(x => {
        let cellMarket = selectMarket(props.game.markets, x)
        if (x === 'pinnacle') {
            cellMarket = pinnyMarket
        }
        let topSide, bottomSide
        if (cellMarket.sides[0].name === topName) {
            topSide = cellMarket.sides[0]
            bottomSide = cellMarket.sides[1]
        } else {
            topSide = cellMarket.sides[1]
            bottomSide = cellMarket.sides[0]
        }

        if (cellMarket.sides[0].point === '') {
            let cellClass = (x.startsWith('pinnacle')) ? 'pinny-cell' : ''
            cellClass = `${cellClass}${x.startsWith('fanduel') ? 'hard-edge' : 'vegas-edge'}`
            return <td className={cellClass} key={x}></td>
        }

        topSide.priceString = `${topSide.price > 0 ? '+' : ''}${topSide.price}`
        bottomSide.priceString = `${bottomSide.price > 0 ? '+' : ''}${bottomSide.price}`

        let topParens = topSide.price === -110 ? "" : `${topSide.priceString}`
        let botParens = bottomSide.price === -110 ? "" : `${bottomSide.priceString}`
        topSide.pointString = (topSide.point < 0 || props.betType.startsWith('totals') ? '' : '+')  + topSide.point
        bottomSide.pointString = (bottomSide.point < 0 || props.betType.startsWith('totals')  ? '' : '+')  + bottomSide.point

        const trueMean = props.referenceType === 'pinnacle' ? trueMeanPinny : trueMeanMedian
        let topSideWinPercent = .5*(1+erf((topSide.point-trueMean)/(stdDev*(2**.5))))
        if (props.betType.startsWith('totals')) {
            topSideWinPercent = 1-topSideWinPercent
        }

        let botSideWinPercent = 1-topSideWinPercent

        // if it crosses 0, reduce the win prob. Not correct but should be good enough
        if (sign(topSide.point) !== sign(trueMean)) {
            topSideWinPercent = reduceWinProb(topSideWinPercent)
            botSideWinPercent = reduceWinProb(botSideWinPercent)
        }
        const topWinPString = `${Math.round(topSideWinPercent*1000)/10}%`
        const topSideEdge = topSideWinPercent*(americanToDecimal(topSide.price)-1)-1+topSideWinPercent
        const topSideEdgeString = `${Math.round(topSideEdge*1000)/10}%`

        const botWinPString = `${Math.round(botSideWinPercent*1000)/10}%`
        const botSideEdge = botSideWinPercent*(americanToDecimal(bottomSide.price)-1)-1+botSideWinPercent
        const botSideEdgeString = `${Math.round(botSideEdge*1000)/10}%`
        const maxEdge = Math.max(topSideEdge, botSideEdge)

        const isBigBet = maxEdge >= .04 && trueMean !== 0
        const isSmall = maxEdge > 0.01 && trueMean !== 0
        let cellClass = !props.game.isStarted && isBigBet ? 'isbettable' :
            !props.game.isStarted && isSmall ? 'smallbet' : ''

        if (props.betType === 'h2h') {
            const topBet = americanToPercent(topSide.price) < (1-americanToPercent(pinnyBottomSide.price))
            const bottomBet = americanToPercent(bottomSide.price) < (1-americanToPercent(pinnyTopSide.price))
            cellClass = topBet || bottomBet ? 'isbettable' : ''
        }
        if (cellClass === '') {
            cellClass =  (x.startsWith('pinnacle')) ? 'pinny-cell' : cellClass
            cellClass = `${cellClass}${x.startsWith('fanduel') ? 'hard-edge' : 'vegas-edge'}`
        }
        if (props.betType.startsWith('h2h')) {
            topSide.pointString = ''
            bottomSide.pointString = ''
        }
        const topHoverText = `Edge: ${topSideEdgeString}\nWin %: ${topWinPString}`
        const botHoverText = `Edge: ${botSideEdgeString}\nWin %: ${botWinPString}`
        return (
            <td key={x} className={cellClass}>
                <span title={topHoverText}>{topSide.pointString} <i >{topParens}</i></span> <br/>
                <span title={botHoverText}>{bottomSide.pointString} <i>{botParens}</i> </span>
            </td>
        )
    })

    let startTime = new Date(props.game.commence_time)
    let startDateString = startTime.toLocaleDateString("en-US")
    startDateString = startDateString.substring(0, startDateString.length-5)
    return (
    <tr className={props.game.isStarted ? 'startedgame' : ''}>
        <td>{startDateString} {startTime.toLocaleTimeString()}</td>
        <td>{props.game.away_team}<br/>{props.game.home_team}</td>
        {rowCells}
    </tr>
    )
}

export default OddsRow;
